import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative min-w-[94px] max-w-[282px] break-words"
}
const _hoisted_2 = { class: "flex flex-col gap-1 rounded-md rounded-tl-none bg-white p-1 before:absolute before:-left-2 before:top-0 before:border-b-[12px] before:border-r-[12px] before:border-b-transparent before:border-r-white before:content-['']" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src", "type"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "flex items-center justify-between gap-3 p-1" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "mb-0 ml-auto text-xs font-medium leading-4 text-grey-600" }
const _hoisted_11 = {
  key: 0,
  class: "mb-0 mt-1 flex w-full min-w-[94px] max-w-[282px] flex-col gap-1 pl-0 text-[#0096de]"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_phone_fill = _resolveComponent("phone-fill")!
  const _component_list_unordered2_fill = _resolveComponent("list-unordered2-fill")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative flex h-full min-h-[234px] w-full flex-col items-center justify-center overflow-y-auto rounded-xl bg-contain bg-center py-12 pl-[41.25px] pr-[35.25px] shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,0.1)]",
    style: _normalizeStyle(`background-image: url('${_ctx.WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL}')`)
  }, [
    (_ctx.isContentVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (!!_ctx.templateHeader && _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.TEXT)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "mb-0 px-1 pt-1 text-base font-semibold leading-6 text-grey-800",
                  innerHTML: _ctx.formattedText(_ctx.templateHeader)
                }, null, 8 /* PROPS */, _hoisted_3))
              : _createCommentVNode("v-if", true),
            (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.headerMediaSrc,
                  class: "rounded-[4px]"
                }, null, 8 /* PROPS */, _hoisted_4))
              : _createCommentVNode("v-if", true),
            (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.VIDEO)
              ? (_openBlock(), _createElementBlock("video", {
                  key: 2,
                  src: _ctx.headerMediaSrc,
                  class: "rounded-[4px]",
                  controls: "",
                  preload: "metadata",
                  controlsList: "nodownload",
                  playsinline: ""
                }, [
                  _createElementVNode("source", {
                    src: _ctx.headerMediaSrc,
                    type: _ctx.ALLOWED_VIDEO_FORMATS[0]
                  }, null, 8 /* PROPS */, _hoisted_6),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.video_not_supported')), 1 /* TEXT */)
                ], 8 /* PROPS */, _hoisted_5))
              : _createCommentVNode("v-if", true),
            _createElementVNode("p", {
              class: "mb-0 px-1 py-2 text-sm font-medium leading-5 text-grey-800",
              innerHTML: _ctx.formattedText(_ctx.templateBody)
            }, null, 8 /* PROPS */, _hoisted_7),
            _createElementVNode("div", _hoisted_8, [
              (!!_ctx.templateFooter)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "mb-0 flex-1 break-words text-xs font-medium leading-4 text-grey-600",
                    innerHTML: _ctx.formattedText(_ctx.templateFooter)
                  }, null, 8 /* PROPS */, _hoisted_9))
                : _createCommentVNode("v-if", true),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.currentTime), 1 /* TEXT */)
            ])
          ]),
          (_ctx.filteredButtons.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredButtons, (btn, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: btn.id,
                    class: "mb-0 w-full list-none break-words rounded-md bg-white py-2 text-center text-base font-normal leading-5"
                  }, [
                    (index < 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          (btn.subType === _ctx.WA_TEMPLATE_COMPONENT_SUB_TYPE.URL)
                            ? (_openBlock(), _createBlock(_component_external_link2_linear, {
                                key: 0,
                                class: "mr-2 inline"
                              }))
                            : _createCommentVNode("v-if", true),
                          (btn.subType === _ctx.WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER)
                            ? (_openBlock(), _createBlock(_component_phone_fill, {
                                key: 1,
                                class: "mr-2 inline"
                              }))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("span", null, _toDisplayString(btn.value), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (index === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createVNode(_component_list_unordered2_fill, { class: "mr-2 inline" }),
                          (_ctx.filteredButtons.length > 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('whatsapp.template_see_all_options')), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}