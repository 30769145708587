<template>
  <div
    class="relative flex h-full min-h-[234px] w-full flex-col items-center justify-center overflow-y-auto rounded-xl bg-contain bg-center py-12 pl-[41.25px] pr-[35.25px] shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,0.1)]"
    :style="`background-image: url('${WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL}')`"
  >
    <div v-if="isContentVisible" class="relative min-w-[94px] max-w-[282px] break-words">
      <div
        class="flex flex-col gap-1 rounded-md rounded-tl-none bg-white p-1 before:absolute before:-left-2 before:top-0 before:border-b-[12px] before:border-r-[12px] before:border-b-transparent before:border-r-white before:content-['']"
      >
        <p
          v-if="!!templateHeader && selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT"
          class="mb-0 px-1 pt-1 text-base font-semibold leading-6 text-grey-800"
          v-html="formattedText(templateHeader)"
        />
        <img v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE" :src="headerMediaSrc" class="rounded-[4px]" />
        <video
          v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO"
          :src="headerMediaSrc"
          class="rounded-[4px]"
          controls
          preload="metadata"
          controlsList="nodownload"
          playsinline
        >
          <source :src="headerMediaSrc" :type="ALLOWED_VIDEO_FORMATS[0]" />
          {{ $t('whatsapp.video_not_supported') }}
        </video>
        <p class="mb-0 px-1 py-2 text-sm font-medium leading-5 text-grey-800" v-html="formattedText(templateBody)" />
        <div class="flex items-center justify-between gap-3 p-1">
          <p
            v-if="!!templateFooter"
            class="mb-0 flex-1 break-words text-xs font-medium leading-4 text-grey-600"
            v-html="formattedText(templateFooter)"
          />
          <p class="mb-0 ml-auto text-xs font-medium leading-4 text-grey-600">{{ currentTime }}</p>
        </div>
      </div>
      <ul
        v-if="filteredButtons.length"
        class="mb-0 mt-1 flex w-full min-w-[94px] max-w-[282px] flex-col gap-1 pl-0 text-[#0096de]"
      >
        <li
          v-for="(btn, index) in filteredButtons"
          :key="btn.id"
          class="mb-0 w-full list-none break-words rounded-md bg-white py-2 text-center text-base font-normal leading-5"
        >
          <span v-if="index < 2">
            <external-link2-linear v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.URL" class="mr-2 inline" />
            <phone-fill v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER" class="mr-2 inline" />
            <span>{{ btn.value }}</span>
          </span>
          <span v-if="index === 2">
            <list-unordered2-fill class="mr-2 inline" />
            <span v-if="filteredButtons.length > 2">{{ $t('whatsapp.template_see_all_options') }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { ListUnordered2Fill, ExternalLink2Linear, PhoneFill } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import {
  TEMPLATE_HEADER_TYPE,
  WA_TEMPLATE_COMPONENT_SUB_TYPE,
  ALLOWED_VIDEO_FORMATS,
  WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL,
} from '@/Configs/Constants/whatsapp';
import { formatWhatsAppText } from '@/util/GlobalHelpers';

import type { TemplateButtonItem } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TemplatePreview',
  components: { ExternalLink2Linear, ListUnordered2Fill, PhoneFill },
  props: {
    buttons: {
      type: Array as PropType<TemplateButtonItem[]>,
      default: () => [],
    },
    selectedHeaderType: {
      type: String,
      default: 'text',
    },
    templateBody: {
      type: String,
      default: '',
    },
    templateHeader: {
      type: String,
      default: '',
    },
    templateFooter: {
      type: String,
      default: '',
    },
    isCreateMode: Boolean,
  },
  data() {
    return {
      currentTime: moment().format('HH:mm'),
      timeIntervalId: null,
      TEMPLATE_HEADER_TYPE,
      WA_TEMPLATE_COMPONENT_SUB_TYPE,
      ALLOWED_VIDEO_FORMATS,
      WHATSAPP_TEMPLATE_PREVIEW_IMAGE_URL,
    };
  },
  computed: {
    defaultHeaderSrc(): string {
      return `${this.$root.assetsURL}img/wabusiness/template-image.svg`;
    },
    headerMediaSrc() {
      return this.isCreateMode && this.templateHeader ? this.templateHeader : this.defaultHeaderSrc;
    },
    isContentVisible(): boolean {
      return Boolean(
        this.templateBody ||
          this.templateHeader ||
          this.templateFooter ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.VIDEO,
      );
    },
    filteredButtons(): TemplateButtonItem[] {
      return (this.buttons as TemplateButtonItem[]).slice(0, 3).filter((btn: TemplateButtonItem) => btn.value);
    },
  },
  mounted() {
    this.setCurrentTime();
  },
  beforeUnmount() {
    clearInterval(this.timeIntervalId);
  },

  methods: {
    formattedText(text: string): string {
      return formatWhatsAppText(text);
    },
    setCurrentTime(): void {
      this.timeIntervalId = setInterval(() => {
        this.currentTime = moment().format('HH:mm');
      }, 1000);
    },
  },
});
</script>
